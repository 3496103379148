import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import head from 'lodash.head';
import ReactMarkdown from 'react-markdown';

import Layout from '../components/layout';
import Section from '../components/section';
import Footer from '../components/footer';

import contactStyles from './contact.module.scss';

const ContactPage = () => (
  <StaticQuery
    query={graphql`
      query Contact {
        allContentfulContact {
          edges {
            node {
              id
              title
              navigationBackgroundColor
              headline
              headlineColor
              backgroundColor
              textColor
              longText {
                longText
              }
              imageAltText
              image {
                file {
                  url
                  fileName
                  contentType
                }
              }
              seoTitle
              seoKeywords {
                seoKeywords
              }
              seoDescription {
                seoDescription
              }
              footer {
                id
                links {
                  id
                  externalUrl
                  slug
                  displayName
                }
                logo {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulContact }) => {
      const {
        navigationBackgroundColor,
        headline,
        headlineColor,
        textColor,
        longText,
        image,
        imageAltText,
        backgroundColor,
        seoTitle,
        seoDescription,
        footer,
      } = head(allContentfulContact.edges).node;

      return (
        <Layout
          seoTitle={seoTitle}
          seoDescription={seoDescription.seoDescription}
          seoKeywords={seoDescription.seoDescription}
          navigationBackgroundColor={navigationBackgroundColor}
        >
          <Section node={{ title: 'Contact us' }} backgroundColor={backgroundColor}>
            <div className={contactStyles.wrapper}>
              <div className={contactStyles.textContainer}>
                <h1 className={contactStyles.headline} style={{ color: headlineColor }}>
                  {headline}
                </h1>
                <div style={{ color: textColor }}>
                  <ReactMarkdown className={contactStyles.text} source={longText.longText} />
                </div>
              </div>
              <div className={contactStyles.imageContainer}>
                <img src={image.file.url} alt={imageAltText} />
              </div>
            </div>
          </Section>
          <Footer node={footer} />
        </Layout>
      );
    }}
  />
);

export default ContactPage;
